import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function PersonalInfo(props) {
  const {data}=props
  const _handleChange = (prop) => (event) => {
    props.handleChange(prop,event.target.value)
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField   
            id="first_name"
            name="first_name"
            label="Name"
            fullWidth
            variant="outlined"
            required
            value={data.name} 
            error={data.required.name=== "error"}
            onChange={_handleChange('name')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={data.email}
            error={data.required.email=== "error"}
            onChange={_handleChange('email')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField  
            required 
            id="password"
            name="password"
            label="Password"
            fullWidth
            variant="outlined"
            type="password"
            value={data.password}
            error={data.required.password=== "error"}
            onChange={_handleChange('password')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="confirm_password"
            name="confirm_password"
            label="Confirm Password"
            fullWidth
            variant="outlined"
            type="password"
            value={data.confirm_password}
            error={data.required.confirm_password=== "error"}
            onChange={_handleChange('confirm_password')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="mobile"
            name="mobile"
            label="Mobile"
            fullWidth
            variant="outlined"
            value={data.mobile}
            error={data.required.mobile=== "error"}
            onChange={_handleChange('mobile')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="company_name"
            name="company_name"
            label="Company Name"
            fullWidth
            variant="outlined"
            value={data.company_name}
            error={data.required.company_name=== "error"}
            onChange={_handleChange('company_name')}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}