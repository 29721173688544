import styled from "styled-components";
const FooterStyled = styled.div`
    padding: 24px 16px;
    margin-top: auto;
    // background-color: rgba(0, 0, 0, 0.87);
    .footer-hr-align-middle {
        border: 1px solid #3A3A44;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        width: 100%
    }

    .footer-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.15px;
        color: rgba(255, 255, 255, 0.87);
    }

    .footer-copyright {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.87);
    }

    .footer-links-captions {
        font-size: 16px;
        padding: 3px;
        line-height: 24px;
        letter-spacing: 0.44px;
        color: rgba(255, 255, 255, 0.87);
    }

    .footer-links {
        text-decoration: none;
        padding: 3px;
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.87);
    }
`;

export default FooterStyled;