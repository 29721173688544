import * as React from 'react';
import {
  CssBaseline,
  Grid,
  Card,
  Container,
  CardContent,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ActionCreators } from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginForm from '../form/Login'
const theme = createTheme();

const SignIn = (props) => {
const onLoginClick = (value) => {
  props.actions.signInUser(value)
}
const user_detail = useSelector(state => state.userAuthReducers.user);
 

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{paddingTop:'80px'}}>
        <CssBaseline />
        <Card>
          <CardContent>
        <Grid container direction='row'>
        <LoginForm signInUser={onLoginClick} user_detail={user_detail}/>
        </Grid>
        </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

export default connect(null, mapDispatchToProps)(SignIn);