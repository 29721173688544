import axios from '../utils/axios.jsx';
import { commonUtils } from '../utils'
export const contractRequestsSave = async (data) => {
    try {
        let token = commonUtils.get_auth()
        let params=JSON.stringify(data);
        const response = await axios.post('/contractrequest', params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getContractRequestsList = async () => {
    try {
        let token = commonUtils.get_auth()
        const response = await axios.get('/contractrequest', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

