import * as React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Link
} from '@mui/material';

export default function ContractRequests(props) {
  const { title,count,url }=props
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h6" component="div">
        {title}
        </Typography>        
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        </Typography>
        <Typography variant="h5" component="div">
        <b>{count}</b>
        </Typography>
      </CardContent>
      <CardActions>
      <Link href={url} underline="hover">
        {"View Details"}
      </Link>
      </CardActions>
    </Card>
  );
}
