import React, { useEffect,useState } from 'react'
import { Container, CssBaseline, makeStyles } from '@material-ui/core'
import Header from '../../components/surfaces/header'
import Footer from '../../components/surfaces/footer';
import DefaultStyled from './default.styles'
import { ActionCreators } from '../../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routesNames } from '../../constants';
import {useLocation} from 'react-router-dom'
const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  main: {
    paddingTop: theme.spacing(2),
  },
}))
const fullPageUrl =['/','/registration','/registration/retailer']

const AppLayout = (props) => {
  const classes = useStyles()
  const location = useLocation()
  const [route, setRoute] = useState('')
  const [isShowMaster, setIsShowMaster] = useState(true)
  useEffect(() => {
    setRoute(location.pathname)
  },[location.pathname])

  useEffect(() => {
    if (fullPageUrl.includes(route)) {
      setIsShowMaster(false)
    } else {
      setIsShowMaster(true)
    }
  }, [route])

  const userLogout = () => {
    props.actions.signOutUser()
  }
  const auth_state = useSelector(storeState => storeState.userAuthReducers.user);
  const onNavigateTo = (key) => {
    if (key === "login") {
      props.actions.onNavigateTo(routesNames.LOGIN)
    }
  }

  const fullScreenLayout = () => {
    return (
      <React.Fragment>
        <DefaultStyled className={classes.root}>
          <Container maxWidth={false} disableGutters >
            {props.children}
          </Container>
        </DefaultStyled>
      </React.Fragment>
    )
  }

  const defaultLayout = () => {
    return (
      <React.Fragment>
        <DefaultStyled className={classes.root}>
        <Header
          position="static"
          userAuth={auth_state.data}
          logout={userLogout}
          onNavigateTo={onNavigateTo}
          isCheckUserAuth={false}
        />
          <CssBaseline />
          <Container maxWidth={false} disableGutters className={classes.main}>
            {props.children}
          </Container>
          <CssBaseline />
          <Footer />
        </DefaultStyled>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
        {isShowMaster ? defaultLayout() : fullScreenLayout()}
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath)
});
export default connect(null, mapDispatchToProps)(AppLayout);
 