import * as React from 'react';
import DataTable from '../../components/DataTable/DataTable';
export default function ContractRequests(props) {
const {title,data,columns,options}=props
  return (
      <DataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
}
