import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export default function PersonalInfo(props) {
  const { data } = props;
  const _handleChange = (prop) => (event) => {
    props.handleChange(prop, event.target.value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="first_name"
          name="first_name"
          label="Name"
          fullWidth
          variant="outlined"
          required
          value={data.fname}
          error={data.required.fname === "error"}
          onChange={_handleChange("fname")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="lname"
          name="lname"
          label="Last Name"
          fullWidth
          variant="outlined"
          value={data.email}
          error={data.required.lname === "error"}
          onChange={_handleChange("lname")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="ssn"
          name="ssn"
          label="SSN"
          fullWidth
          variant="outlined"
          value={data.ssn}
          error={data.required.ssn === "error"}
          onChange={_handleChange("ssn")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="zipcard"
          name="zipcard"
          label="Zip Code"
          fullWidth
          variant="outlined"
          value={data.zipcard}
          error={data.required.zipcode === "error"}
          onChange={_handleChange("zipcode")}
        />
      </Grid>
    </Grid>
  );
}
