import React, { useEffect } from "react";
import {
  CssBaseline,
  Grid,
  Card,
  Container,
  CardContent,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ActionCreators } from "../redux/actions";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Alerts from "../components/Alert/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import CreditApplication from "../form/CreditApplication";
import { useParams } from "react-router-dom";
const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  clstitle: {
    padding: "3px",
    fontSize: "15px !important",
    opacity: 0.8,
  },
  clsHeader: {
    fontSize: "22px",
    opacity: 0.8,
    padding: "10px 0px 30px 0px",
  },
}));

const Registration = (props) => {
  const classes = useStyles();
  const { slug } = useParams();
  useEffect(() => {
    props.actions.getReatilerDetail(slug);
  }, [props.actions]);

  const [values, setValues] = React.useState({
    data: {
      fname: "",
      lname: "",
      ssn: "",
      zipcode: "",
    },
    required: {
      fname: "",
      lname: "",
      ssn: "",
      zipcode: "",
    },
  });

  const onHandleSave = () => {
    if (validation()) {
      props.actions.saveConsumerInfo(values.data);
    }
  };

  const validation = () => {
    var reqData = values["required"];
    var isValid = true;
    if (
      !values.data.hasOwnProperty("fname") ||
      values.data.fname.trim() === "" ||
      reqData["fname"] === "error"
    ) {
      reqData["fname"] = "error";
      isValid = false;
    }

    if (
      !values.data.hasOwnProperty("lname") ||
      values.data.lname.trim() === "" ||
      reqData["lname"] === "error"
    ) {
      reqData["lname"] = "error";
      isValid = false;
    }

    if (
      !values.data.hasOwnProperty("ssn") ||
      values.data.ssn.trim() === "" ||
      reqData["ssn"] === "error"
    ) {
      reqData["ssn"] = "error";
      isValid = false;
    }

    if (
      !values.data.hasOwnProperty("zipcode") ||
      values.data.zipcode.trim() === "" ||
      reqData["zipcode"] === "error"
    ) {
      reqData["zipcode"] = "error";
      isValid = false;
    }

    setValues({
      ...values,
      required: reqData,
    });
    return isValid;
  };

  const handleChange = (name, value) => {
    var validData = "success";
    let required = values.required;
    let data = values.data;
    required[name] = validData;
    data[name] = value;
    setValues({
      ...values,
      ["data"]: data,
      ["required"]: required,
    });
  };

  const consumer_detail = useSelector((state) => state.ConsumerReducers.data);
  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <CssBaseline />
      <Card>
        <CardContent>
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
            >
              <Box>
                <Typography
                  variant="h5"
                  component="div"
                  justifyContent="center"
                  className={classes.clsHeader}
                >
                  <b> Consumer Info </b>
                  <br />
                </Typography>
              </Box>
            </Grid>
            <Box>
              <CreditApplication data={values} handleChange={handleChange} />
            </Box>

            {consumer_detail && consumer_detail.error !== null && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box sx={{ marginTop: "10px" }}>
                    <Alerts type="error" message={consumer_detail.error} />
                  </Box>
                </Grid>
              </>
            )}

            {consumer_detail?.data !== null && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box sx={{ marginTop: "10px" }}>
                    <Alerts type="success" message={consumer_detail?.data} />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ float: "right" }}>
                <LoadingButton
                  color="primary"
                  onClick={() => onHandleSave()}
                  loading={consumer_detail.loading}
                  loadingPosition="start"
                  sx={{ mt: 3, mb: 2, marginRight: "5px" }}
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
});

export default connect(null, mapDispatchToProps)(Registration);
