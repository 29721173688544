import React,{useEffect} from 'react';
import Grid from '@mui/material/Grid';
import {TextField,Button} from '@mui/material';
import DatePicker from '../../components/DatePicker';
import moment from 'moment';
import Alerts from '../../components/Alert/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ContractRequests(props) {

  const { data,handleSave,handleClose ,response,loadDashboard}=props
  const _handleChange = (prop) => (event) => {
    props.handleChange(prop,event.target.value)
  };

  const handleDateChange = name => event => {
    props.handleChange(name,moment(event).format("YYYY-MM-DD"))
  }
useEffect(()=>{
  if(response?.data?.status===1){
  handleClose()
  loadDashboard()
  }
},[response,handleClose,loadDashboard])

  return (
    <>
    <br/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField   
            id="description"
            name="description"
            label="Description"
            fullWidth
            variant="outlined"
            required
            value={data.data.description} 
            error={data.required.description=== "error"}
            onChange={_handleChange('description')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker 
          label={'Valid Start'}
          handleDateChange={handleDateChange('valid_start')}  
          value={data.data.valid_start}
          required={data.required.valid_start=== "error"}
          />
        </Grid>
  
        <Grid item xs={12} sm={6}>
          <DatePicker
          label={'Valid End'}
          handleDateChange={handleDateChange('valid_end')}  
          value={data.data.valid_end}
          error={data.required.valid_end=== "error"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min_score"
            name="min_score"
            label="Min Score"
            fullWidth
            variant="outlined"
            value={data.data.min_score}
            error={data.required.min_score=== "error"}
            onChange={_handleChange('min_score')}
          />
        </Grid>
        

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="internal_priority"
            name="internal_priority"
            label="Internal Priority"
            fullWidth
            variant="outlined"
            value={data.data.internal_priority}
            error={data.required.internal_priority=== "error"}
            onChange={_handleChange('internal_priority')}
          />
        </Grid>
        { response?.status===0 &&  <Grid item xs={12} sm={12} lg={12}>
          <Alerts type="error" message={response?.message}/>
          </Grid>
          }
       <Grid item xs={12} sm={12} lg={12}>
        <Button 
        variant="contained"
        sx={{ float:'right',marginLeft:'12px'}}
        onClick={handleClose}
        >
          Cancel
      </Button>
      &nbsp;
          <LoadingButton
            color="primary"  
            onClick={handleSave}
            loading={response.loading}
            loadingPosition="start"
            sx={{ float:'right'}}
            variant="contained"
          > 
            Save 
          </LoadingButton>
          </Grid>
      </Grid>
   </>
  );
}