import axios from '../utils/axios.jsx';
import { commonUtils } from '../utils'
export const getReatilerDetail = async (id) => {
    try {
        let token = commonUtils.get_auth()
        const response = await axios.get('retailer?slug='+id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const saveConsumerInfo = async (data) => {
    try {
        let token = commonUtils.get_auth()
        const params = JSON.stringify(data);
        const response = await axios.post('/application/submit',params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}