import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable from "mui-datatables";
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiTableRow-root': {
        display: 'flex',
        width: '100%',
        flexFlow: 'wrap',
      },
      '& .MuiTableCell-root': {
        minHeight: 56,
        height: 'unset',
        '& .media-display': {
          width: '100%',
        },
      },
      '& tr > .MuiTableCell-root': {
        maxWidth: '100%',
        display: 'flex',
        whiteSpace: 'unset',
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
      '& tr > .MuiTableCell-root.MuiTableCell-paddingCheckbox': {
        maxWidth: '100%',
        width: '100%',
      },
      '& .quick-clone-wrapper': {
        '& .MuiSpeedDial-actions': {
          display: 'flex',
          flexDirection: 'column',
          marginRight: 8,
        },
      },
      '& .two-actions-wrapper': {
        marginTop: -2,
      },
      '& .media-component': {
        marginLeft: 0,
        '& iframe': {
          height: 74,
        },
      },
    },
    '& tr': {
      cursor: 'context-menu'
    }
  },
  withCheckBox: {
    [theme.breakpoints.down('sm')]: {
      '& tr > .MuiTableCell-root:nth-child(even)': {
        fontWeight: 'bold',
      },
      '& tr > .MuiTableCell-root:nth-child(odd)': {
        position: 'relative',
      },
    },
  },
  withoutCheckBox: {
    [theme.breakpoints.down('sm')]: {
      '& tr > .MuiTableCell-root:nth-child(odd)': {
        fontWeight: 'bold',
      },
      '& tr > .MuiTableCell-root:nth-child(even)': {
        position: 'relative',
      },
    },
  },
}))

export default function DataTable(props ){
  const classes = useStyles()
  const { className, ...other } = props
  return (
    <>
      <MUIDataTable
        className={`${classes.root} ${props.options?.selectableRows
          ? classes.withCheckBox
          : classes.withoutCheckBox
          } ${className}`}
        {...other}
      />
    </>
  )
}
