import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectCompponent(props) {
    const {list,title,name}=props;
    const handleChange = (prop) => (event) => {
        props.handleChange(prop,event.target.value)
      };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={name}
          value={props.value}
          label={title}
          onChange={handleChange(name)}
        >
           {list.map((lable, index) => (  
           <MenuItem value={lable.key}>{lable.value}</MenuItem>
        ))}
        </Select>
      </FormControl>
    </Box>
  );
}
