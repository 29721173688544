import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialThemeProvider, createTheme, StylesProvider } from '@material-ui/core/styles'
import App from './containers/App'

import theme from './config/theme/theme.config'
import AppLayout from './layout'

import {
    configureStore,
    history
} from './redux/store/configureStore'
import { Router } from "react-router-dom";
const store = configureStore()

let themeDetails = theme
let themeBuild = createTheme({
    ...themeDetails,
    palette: {
        ...themeDetails.palette,
        type: 'light',
    },
})
ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
        <Router history={history}>
            <React.StrictMode>
                <StylesProvider injectFirst>
                    <StyledThemeProvider theme={themeBuild}>
                        <MaterialThemeProvider theme={themeBuild}>
                            <AppLayout>
                                <App />
                            </AppLayout>
                        </MaterialThemeProvider>
                    </StyledThemeProvider>
                </StylesProvider>
            </React.StrictMode>
        </Router>
    </BrowserRouter>
</Provider>, document.getElementById('root'));
registerServiceWorker();
