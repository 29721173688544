import * as RetailerService from '../../services/RetailerService';
import * as actionTypes from './actionTypes';


export const getReatilerDetail = (data) =>
	async dispatch => {
		dispatch(getReatilerDetailRequest());
		try {
            
			const user = await RetailerService.getReatilerDetail(data);

			if (user && user.data && user.data.status === 1) {
				dispatch(getReatilerDetailLoaded(user.data.data));
			} else {
				dispatch(getReatilerDetailLoaded(user.data.message));
			}
		} catch (error) {
			dispatch(getReatilerDetailFailure(error.message));
		}
	}

const getReatilerDetailRequest = () => {
	return {
		type: actionTypes.GET_RETAILER_DETAIL_REQUEST
	}
}

const getReatilerDetailFailure = (error) => {
	return {
		type: actionTypes.GET_RETAILER_DETAIL_FAILURE,
		payload: error
	}
}

const getReatilerDetailLoaded = (data) => {
	return {
		type: actionTypes.GET_RETAILER_DETAIL_SUCCESS,
		payload: data
	}
}

	