import * as React from 'react';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {TextField} from '@mui/material';


export default function DatePickerControl(props) {
  const{ value,handleDateChange,label,requred}=props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={handleDateChange}
        error={requred}
        renderInput={(params) => <TextField {...params} />}
      />
      </Stack>
      </LocalizationProvider>
  );
}
