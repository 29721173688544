import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {

    user: {
        data: null,
        error: null,
        loading: false
    },
    userDetail: {
        data: null,
        error: null,
        loading: false
    },
    userRegistration: {
        data: null,
        error: null,
        loading: false
    },
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actionTypes.LOGIN_REQUEST: {
            return {
                ...state,
                user: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }
        case actionTypes.LOGIN_LOADED: {
            return {
                ...state,
                user: {
                    data: action.payload,
                    error: null,
                    loading: false
                }
            };
        }
        case actionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                user: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }

 

        case actionTypes.SAVE_REGISTRATION_REQEST: {
            return {
                ...state,
                userRegistration: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }
        case actionTypes.SAVE_REGISTRATION_LOADED: {

            return {
                ...state,
                userRegistration: {
                    data: action.payload,
                    error: null,
                    loading: false
                }
            };
        }
        case actionTypes.SAVE_REGISTRATION_FAILURE: {
            return {
                ...state,
                userRegistration: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }


        default:
            return state;
    }
};

export default authReducer;