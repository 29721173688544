
export const LOGIN_REQUEST = 'login_request';
export const LOGIN_FAILURE = 'login_failure';
export const LOGIN_LOADED = 'login_loaded'
export const SIGN_OUT = 'logout';

export const SEND_FORGOT_PASSWORD_REQUEST = 'send_forgot_password_request';
export const SEND_FORGOT_PASSWORD_FAILURE = 'send_forgot_password_failure';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'send_forgot_password_success';

export const SAVE_REGISTRATION_REQEST = 'save_registration_reqest';
export const SAVE_REGISTRATION_LOADED = 'save_registration_loaded';
export const SAVE_REGISTRATION_FAILURE = 'save_registration_failure';

export const USER_MINE_REQEST = 'user_mine_reqest';
export const USER_MINE_LOADED = 'user_mine_loaded';
export const USER_MINE_FAILURE = 'user_mine_failure';

export const RESET_PASSWORD_REQUEST = 'reset_password_request';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
