import {createMuiTheme} from '@material-ui/core/styles'

// Create a theme instance.

const AppTheme = {
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: '#203FBC',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
}

const theme = createMuiTheme(AppTheme)

export default theme

