import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {

    data: {
        data: null,
        error: null,
        loading: false
    }
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actionTypes.SAVE_COUNSUMER_INFO_REQUEST: {
            return {
                ...state,
                data: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }
        case actionTypes.SAVE_COUNSUMER_INFO_SUCCESS: {
            return {
                ...state,
                data: {
                    data: action.payload,
                    error: null,
                    loading: false
                }
            };
        }
        case actionTypes.SAVE_COUNSUMER_INFO_FAILURE: {
            return {
                ...state,
                data: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }

        default:
            return state;
    }
};

export default authReducer;