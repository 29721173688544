import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Select from '../../components/Select';

const pos_type=[{
key:'pinogy',
value:"Pinogy"
},{
    key:'lightspeed',
    value:"Lightspeed"
    }]
export default function RetailerInfo(props) {
  const {data}=props
  const _handleChange = (prop) => (event) => {
    props.handleChange(prop,event.target.value)
  };
  const _handleSelectChange = (name,value) => {
  props.handleChange(name,value)
};
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField   
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            required
            value={data.name} 
            error={data.required.name=== "error"}
            onChange={_handleChange('name')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Select list={pos_type} 
        value={data.pos_type} 
        title="POS Type"  
        value={data.pos_type} 
        handleChange={_handleSelectChange}
        name="pos_type"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField  
            required 
            id="password"
            name="password"
            label="Password"
            fullWidth
            variant="outlined"
            type="password"
            value={data.password}
            error={data.required.password=== "error"}
            onChange={_handleChange('password')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="confirm_password"
            name="confirm_password"
            label="Confirm Password"
            fullWidth
            variant="outlined"
            type="password"
            value={data.confirm_password}
            error={data.required.confirm_password=== "error"}
            onChange={_handleChange('confirm_password')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={data.email}
            error={data.required.email=== "error"}
            onChange={_handleChange('email')}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}