import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {

    save: {
        data: null,
        error: null,
        loading: false
    },
    list: {
        data: null,
        error: null,
        loading: false
    },
   
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actionTypes.CONTRACTREQUEST_REQUEST: {
            return {
                ...state,
                save: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }
        case actionTypes.CONTRACTREQUEST_LOADED: {
            return {
                ...state,
                save: {
                    data: action.payload,
                    error: null,
                    loading: false
                }
            };
        }
        case actionTypes.CONTRACTREQUEST_FAILURE: {
            return {
                ...state,
                save: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }
        case actionTypes.CONTRACTREQUEST_INIT:{
            return{
                ...state,
                save: {
                    data: null,
                    error: null,
                    loading: false
                }
            }
        }
        case actionTypes.CONTRACTREQUESTLIST_REQUEST: {
            return {
                ...state,
                list: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }
        case actionTypes.CONTRACTREQUESTLIST_LOADED: {
            return {
                ...state,
                list: {
                    data: action.payload,
                    error: null,
                    loading: false
                }
            };
        }
        case actionTypes.CONTRACTREQUESTLIST_FAILURE: {
            return {
                ...state,
                list: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }

        default:
            return state;
    }
};

export default authReducer;