import  React from 'react';
import {
  CssBaseline,
  Grid,
  Card,
  Container,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ActionCreators } from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddressForm from '../form/Address'
import PersonalInfo from '../form/PersonalInfo'
import { routesNames } from '../constants';
import Alerts from '../components/Alert/Alert';
import RetailerInfo from '../form/RetailerInfo';
import LoadingButton from '@mui/lab/LoadingButton';

const theme = createTheme();

const Registration = (props) => {
  const {type}=props;
  const [values, setValues] = React.useState({
    data: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        company_name: '',
        mobile: '',
        address:{
          city: '',
          country: '',
          line1: '',
          line2: '',
          zipcode: '',
        }
    },
    required: {
      name: '',
      email: '',
      password: '',
      company_name: '',
      mobile: '',
      address:{
        city: '',
        country: '',
        line1: '',  
        zipcode: '',
      }
    }
  })

  const handleChange =(name,value) => {
    var validData = "success";
    let required = values.required;
    let data = values.data
    required[name] = validData
    data[name] = value
        setValues({
            ...values,
            ['data']: data,
            ['required']: required
        });
};

const handleChangeAddress =(name,value) => {
  var validData = "success";
  let required = values.required;
  let data = values.data
  required.address[name] = validData
  data.address[name] = value
      setValues({
          ...values,
          ['data']: data,
          ['required']: required
      });
};

const onHandleSave=()=>{
  if(validation()){
      props.actions.userRegistration(values.data,type)
  }
}

const user_detail = useSelector(state => state.userAuthReducers.userRegistration);
 
const validation = () => {
  var reqData = values['required'];
  var isValid = true;
  if (!values.data.hasOwnProperty("name") || (values.data.name.trim() === "" || reqData["name"] === "error")) {
      reqData["name"] = "error";
      isValid = false;
  }

  if (!values.data.hasOwnProperty("password") || (values.data.password.trim() === "" || reqData["password"] === "error")) {
      reqData["password"] = "error";
      isValid = false;
  }

  if (!values.data.hasOwnProperty("confirm_password") || (values.data.confirm_password.trim() === "" || reqData["confirm_password"] === "error")) {
    reqData["confirm_password"] = "error";
    isValid = false;
  }

  if (values.data.password!==values.data.confirm_password) {
    reqData["confirm_password"] = "error";
    isValid = false;
  }

  if (!values.data.hasOwnProperty("email") || (values.data.email.trim() === "" || reqData["email"] === "error")) {
    reqData["email"] = "error";
    isValid = false;
  }
  if(type==="lender"){
  if (!values.data.hasOwnProperty("mobile") || (values.data.mobile.trim() === "" || reqData["mobile"] === "error")) {
      reqData["mobile"] = "error";
      isValid = false;
  }

  if (!values.data.hasOwnProperty("company_name") || (values.data.company_name.trim() === "" || reqData["company_name"] === "error")) {
    reqData["company_name"] = "error";
    isValid = false;
  }
}

  if (!values.data.address.hasOwnProperty("line1") || (values.data.address.line1.trim() === "" || reqData.address["line1"] === "error")) {
      reqData.address["line1"] = "error";
      isValid = false;
  }

  if (!values.data.address.hasOwnProperty("country") || (values.data.address.country.trim() === "" || reqData.address["country"] === "error")) {
      reqData.address["country"] = "error";
      isValid = false;
  }

  if (!values.data.address.hasOwnProperty("zipcode") || (values.data.address.zipcode.trim() === "" || reqData.address["zipcode"] === "error")) {
      reqData.address["zipcode"] = "error";
      isValid = false;
  }

  if (!values.data.address.hasOwnProperty("city") || (values.data.address.city.trim() === "" || reqData.address["city"] === "error")) {
      reqData.address["city"] = "error";
      isValid = false;
  }

  setValues({
      ...values,
      'required': reqData
  });
  return isValid;
}

const OnCancelClick=()=>{
  props.actions.onNavigateTo(routesNames.LOGIN)
}

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm" style={{paddingTop:'50px',paddingBottom:'50px'}}>
        <CssBaseline />
        <Card>
          <CardContent>
        <Grid container direction='row'>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:'center'}}> 
          <Box >
            <Typography variant="h5" component="div" justifyContent="center" >
              <b> Registration </b>
              <br/>     
              <br/>
            </Typography>
          </Box>
        </Grid>
        <Box>
        {type==="lender"? <PersonalInfo handleChange={handleChange}  data={values} /> :
          <RetailerInfo handleChange={handleChange}  data={values} />}
        </Box>
          <br/>     
        <Box>
          <AddressForm handleChange={handleChangeAddress} data={values}/>
        </Box>
          {user_detail.error!==null && 
          <>
          <Grid item xs={12} sm={12} md={12} lg={12}> 
            <Box sx={{marginTop:'10px'}}>
              <Alerts type="error" message={user_detail.error}/>
            </Box>
          </Grid>
          </>}
          <Grid item xs={12} sm={12} md={6} lg={6}> 
        <Box sx={{marginRight: '4px'}}>
          <LoadingButton
                              color="primary"
                              onClick={()=>onHandleSave()}
                              loading={user_detail.loading}
                              loadingPosition="start"
                              sx={{ mt: 3, mb: 2 ,marginRight:'5px'}}
                              fullWidth
                              variant="contained"
                            > 
                                       Sign Up
                            </LoadingButton>
        </Box>
       </Grid> 
       <Grid item xs={12} sm={12} md={6} lg={6}> 
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={()=>OnCancelClick()}
          >
            Cancel
          </Button>
       </Grid>
        </Grid>
        </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

export default connect(null, mapDispatchToProps)(Registration);