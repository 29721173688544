
import { getToken } from "./authUtils"

export function get_auth(key) {
    let token = getToken('token');
    return "Bearer " + token
}

export const dashboardCard=[{
    'title':'Active Contract Requests',
    'count':0
},{
    'title':'Upcomming Contract Requests',
    'count':0
}]