import * as userAuthAction from './userAuthAction'
import * as navigationActions from './navigationActions';
import * as contractRequestsAction from './contractRequestsAction';
import * as RetailerActions from './RetailerActions';
import * as ConsumerActions from './ConsumerActions'
export const ActionCreators = Object.assign({},
  userAuthAction,
  navigationActions,
  contractRequestsAction,
  RetailerActions,
  ConsumerActions
)
