import  React,{useState,useEffect} from 'react';
import {Container,Grid,Button} from '@mui/material';
import ContractRequestsForm from '../form/ContractRequests'
import Dialog from '../components/Dialog';
import moment from 'moment';
import { ActionCreators } from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContractRequestsList from '../list/ContractRequests';
import {Edit,Add} from '@mui/icons-material';

const ContractRequestsPage = (props) => {
  const renderActions = (value) => (
    <Grid
      container
      wrap='nowrap'
      alignItems='center'
    >
    <Grid item style={{ marginTop: -2 }}>
        <Edit  onClick={() =>{handleEdit(value)}}/>
      </Grid> 
    </Grid>
  )

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display:false,
       filter: true,
       sort: true,
      }
     },
    {
     name: "description",
     label: "Description",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "internal_priority",
     label: "Internal Priority",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "min_score",
     label: "Min Score",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "valid_end",
     label: "Valid End",
     options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => <>{moment(value).format("YYYY-MM-DD")}</>
     }
    },{
      name: "valid_start",
      label: "Valid State",
      options: {
       filter: true,
       sort: false,
       customBodyRender: (value) => <>{moment(value).format("YYYY-MM-DD")}</>
      }
     },{
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRender: (value) => renderActions(value)
      },
    }
   ];
   
   const options = {
    filter: false,
    viewColumns: false,
    selectableRows: false,
    download: true,
    downloadOptions: {
      filename: 'contractrequests.csv',
    },
    print: false,
    rowsPerPageOptions: [],
  }

  const [values, setValues] = useState({
    data: {
      description: '',
      valid_start: moment(new Date()).format("YYYY-MM-DD"),
      valid_end: moment(new Date()).format("YYYY-MM-DD"),
      min_score: '',
      internal_priority: ''
    },
    required: {
      description: '',
      valid_start: '',
      min_score: '',
      internal_priority: ''
    }
  })

  const [open,setOpen]=useState(false);
  const contractRequestState = useSelector(state => state.contractRequestsReducers.save);
  const contractRequestListState = useSelector(state => state.contractRequestsReducers.list);
  const handleClose = () => {
    setOpen(false);
    props.actions.stateInit()
    stateInit()
  };

  const handleEdit = (id) => {
    let _data=contractRequestListState?.data?.data.filter(a=>a.id===id)
    if(_data.length>0){
      let data = values.data
      data.description= _data[0].description;
      data.valid_start= _data[0].valid_start;
      data.valid_end= _data[0].valid_end;
      data.min_score= _data[0].min_score;
      data.internal_priority= _data[0].internal_priority;
      data.id=id
      setValues({
        ...values,
        ['data']: data
      })
      setOpen(true);
    }
  };

  const loadDashboard=()=>{
    setOpen(false);
    props.actions.stateInit()
    props.actions.getContractRequestsList()
    stateInit()
  }

  const stateInit=()=>{
    const data= {
      description: '',
      valid_start: moment(new Date()).format("YYYY-MM-DD"),
      valid_end: moment(new Date()).format("YYYY-MM-DD"),
      min_score: '',
      internal_priority: ''
    };
    const required={
      description: '',
      valid_start: '',
      min_score: '',
      internal_priority: ''
    };
    setValues({
      ...values,
      ['data']: data,
      ['required']: required
  });
  }
  const handleOpen=()=>{
    setOpen(!open);
  }

  const validation = () => {
    var reqData = values['required'];
    var isValid = true;
  
    if (!values.data.hasOwnProperty("description") || (values.data.description.trim() === "" || reqData["description"] === "error")) {
        reqData["description"] = "error";
        isValid = false;
    }
  
    if (!values.data.hasOwnProperty("valid_start") || (values.data.valid_start.trim() === "" || reqData["valid_start"] === "error")) {
      reqData["valid_start"] = "error";
      isValid = false;
    }

    if (!values.data.hasOwnProperty("min_score") || (values.data.min_score.trim() === "" || reqData["min_score"] === "error")) {
      reqData["min_score"] = "error";
      isValid = false;
    }

    if (!values.data.hasOwnProperty("internal_priority") || (values.data.internal_priority.trim() === "" || reqData["internal_priority"] === "error")) {
      reqData["internal_priority"] = "error";
      isValid = false;
    }

    setValues({
      ...values,
      'required': reqData
  });
  return isValid;
}

  useEffect(()=>{
      props.actions.getContractRequestsList()
  },[props.actions])

    const handleSave=()=>{
      if(validation()){
      props.actions.contractRequestsSave(values.data);
      }
    }
    const handleChange =(name,value) => {
      var validData = "success";
      let required = values.required;
      let data = values.data
      required[name] = validData
      data[name] = value
          setValues({
              ...values,
              ['data']: data,
              ['required']: required
          });
    };

    const onHandleDialog=()=>{
      return(
        <Dialog 
          content={
          <ContractRequestsForm 
            handleChange={handleChange} 
            data={values} 
            handleClose={handleClose} 
            handleSave={handleSave}
            response={contractRequestState}
            loadDashboard={loadDashboard}
          />} 
          title="Contract Requests" 
          open={open} 
          action={null} 
          handleClose={handleClose} 
        />
      )
    }

    return (
        <Container >
          {onHandleDialog()}
          <Grid container spacing={3} >
          <Grid item xs={12} sm={12} lg={12}> 
          <Button 
          variant="contained"
          sx={{ float:'right',marginLeft:'12px'}}
          onClick={()=>handleOpen()}
          >
           <Add sx={{fontSize:'20px'}}/> Contract Requests
        </Button>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}> 
          
          <ContractRequestsList 
          title={"Contract Request"}
          data={contractRequestListState?.data?.data}
          columns={columns}
          options={options}/>
            </Grid>
          </Grid>        
        </Container>
    );
  }
  const mapDispatchToProps = (dispath) => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });

  export default connect(null, mapDispatchToProps)(ContractRequestsPage);