import * as RetailerService from "../../services/RetailerService";
import * as actionTypes from "./actionTypes";

export const saveConsumerInfo = (data) => async (dispatch) => {
  dispatch(saveConsumerInfoRequest());
  try {
    const consumer = await RetailerService.saveConsumerInfo(data);
    if (consumer && consumer.data && consumer.data.status === 1) {
      dispatch(saveConsumerInfoLoaded("Data Save successfully!"));
    } else {
      dispatch(saveConsumerInfoFailure(consumer.data.message));
    }
  } catch (error) {
    dispatch(saveConsumerInfoFailure(error.message));
  }
};

const saveConsumerInfoRequest = () => {
  return {
    type: actionTypes.SAVE_COUNSUMER_INFO_REQUEST,
  };
};

const saveConsumerInfoFailure = (error) => {
  return {
    type: actionTypes.SAVE_COUNSUMER_INFO_FAILURE,
    payload: error,
  };
};

const saveConsumerInfoLoaded = (data) => {
  return {
    type: actionTypes.SAVE_COUNSUMER_INFO_SUCCESS,
    payload: data,
  };
};
