import axios from '../utils/axios.jsx';
import { commonUtils } from '../utils'
export const login = async (data) => {
    try {
        var formData = new FormData();
        formData.append('email', data.email);
        formData.append('password', data.password);
        const response = await axios.post('/login', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const userRegistration = async (data) => {
    try {
        const params = JSON.stringify(data);
        const response = await axios.post('/lender/register', params, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const retailerRegistration = async (data) => {
    try {
        const params = JSON.stringify(data);
        const response = await axios.post('/retailer/register', params, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}



export const mine = async () => {
    try {
        let token = commonUtils.get_auth()
        const response = await axios.get('/userinfo', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}