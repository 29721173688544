import  React,{useState,useEffect} from 'react';
import {
  Container,
  Grid,
  Typography
} from '@mui/material';
import ContractRequests from '../components/Card/ContractRequests';
import { ActionCreators } from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const Dashboard = (props) => {

  const [dashboardCard, setDashboardCard]= useState([{
        'title':'Active Contract Requests',
        'count':0,
        'url':'/contractrequest'
    },{
        'title':'Upcomming Contract Requests',
        'count':0,
        'url':'dashboard'
    }])

  const contractRequestListState = useSelector(state => state.contractRequestsReducers.list);
  
    useEffect(()=>{
        props.actions.getContractRequestsList()
    },[props.actions])

    useEffect(()=>{
        dashboardCard[0].count=contractRequestListState?.data?.data.length;
        setDashboardCard(dashboardCard);
    },[contractRequestListState,dashboardCard])

    return (
        <Container >
          <Grid container spacing={3} >
          <Grid item xs={12} sm={12} lg={12}> 
          <Typography variant="h5" component="div" sx={{float:'left'}} >
          <b> Dashboard</b>
          </Typography>
          </Grid>
            <Grid item xs={12} sm={12} lg={4}> 
              <ContractRequests 
                title={dashboardCard[0].title} 
                url={dashboardCard[0].url}
                count={contractRequestListState?.data?.data.filter(a=>a.is_active===true).length}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={4}> 
                <ContractRequests 
                title={dashboardCard[1].title}
                url={dashboardCard[1].url} 
                count={0}/>
              </Grid>     
          </Grid>        
        </Container>
    );
  }
  const mapDispatchToProps = (dispath) => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });

  export default connect(null, mapDispatchToProps)(Dashboard);