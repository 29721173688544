import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import * as routesNames from '../../constants/routes'

import {
  Login,
  Dashboard,
  HomePage,
  Registration,
  Contractrequests,
  Retailer,
  Consumer
} from '..'
const Routes = (
  <Switch>
    <Route exact path={routesNames.LOGIN} component={Login} />
    <Route exact path={routesNames.DASHBOARD} component={Dashboard} />
    <Route exact path={routesNames.HOMEPAGE} component={HomePage} />
    <Route exact path={routesNames.REGISTRATION} component={({ match, location, history })=><Registration type={'lender'} />}/>
    <Route exact path={routesNames.REGISTRATIONRETAILER} component={({ match, location, history })=><Registration type={'retailer'} />}/>
    <Route exact path={routesNames.CONTRACTREQUESTS} component={Contractrequests} />
    <Route path={routesNames.RETAILER+"/:slug"} component={Retailer} />
    <Route path={routesNames.CONSUMER} component={Consumer} />
  </Switch>
)

export default Routes
