import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import userAuthReducers from "./userAuthReducers"
import contractRequestsReducers from "./contractRequestsReducers"
import RetailerReducers from "./retailerReducers"
import ConsumerReducers from "./ConsumerReducers"

const appReducer = combineReducers({
  routerState: routerReducer,
  userAuthReducers: userAuthReducers,
  contractRequestsReducers:contractRequestsReducers,
  RetailerReducers:RetailerReducers,
  ConsumerReducers:ConsumerReducers
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer

