import * as userAuthService from '../../services/userAuthService';
import * as actionTypes from './actionTypes';
import * as navigationActions from './navigationActions';
import { routesNames } from '../../constants';
import { localStorageUtils } from '../../utils'

export const signInUser = (data) =>
	async dispatch => {
		dispatch(loginRequest());
		try {
			const user = await userAuthService.login(data);
			if (user && user.data && user.data.status === 1) {
				localStorageUtils.setToken("token", user.data.data.access);
				const userInfo=await userAuthService.mine();
				if(userInfo?.data?.data?.groups.includes('Lender User Group'))
				{
					dispatch(navigationActions.onNavigateTo(routesNames.DASHBOARD));
				}else{
					dispatch(navigationActions.onNavigateTo(routesNames.HOMEPAGE));
				}
			} else {
				dispatch(loginFailure(user.data.message));
			}
		} catch (error) {
			dispatch(loginFailure(error.message));
		}
	}

export const signOutUser = () =>
	async dispatch => {
		try {
			dispatch(setUserAuth(null));
			localStorageUtils.removeToken("token");
			dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
		} catch (error) {
			dispatch(setUserAuth(null));
			dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
		}
	}

const loginRequest = () => {
	return {
		type: actionTypes.LOGIN_REQUEST
	}
}

const loginFailure = (error) => {
	return {
		type: actionTypes.LOGIN_FAILURE,
		payload: error
	}
}

const setUserAuth = (data) => {
	return {
		type: actionTypes.LOGIN_LOADED,
		payload: data
	}
}


export const userRegistration = (data,type) =>
	async dispatch => {
		dispatch(userRegistrationRequest());
		try {
			let user=null;
			if(type==="retailer"){
				user = await userAuthService.retailerRegistration(data);		
			}else{
				user= await userAuthService.userRegistration(data);
			}
			if (user && user.data && user.data.status === 1) {
				dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
			} else {
				dispatch(userRegistrationFailure(user.data.message));
			}
		} catch (error) {
			dispatch(userRegistrationFailure(error.message));
		}
	}


const userRegistrationSuccess = (data) => {
	return {
		type: actionTypes.SAVE_REGISTRATION_LOADED,
		payload: data
	}
}

const userRegistrationRequest = () => {
	return {
		type: actionTypes.SAVE_REGISTRATION_REQEST
	}
}

const userRegistrationFailure = (error) => {
	return {
		type: actionTypes.SAVE_REGISTRATION_FAILURE,
		payload: error
	}
}


export const mine = (data) =>
	async dispatch => {
		dispatch(loginRequest());
		try {
			const user = await userAuthService.mine(data);
			if (user && user.status === 200) {
				var userData = {
					id: user.data.user_id,
					name: user.data.user.login,
				};
				dispatch(setUserAuth(userData));

			} else {
				dispatch(setUserAuth(null));
			}
		} catch (error) {
			dispatch(loginFailure(error.message));
		}
	}


