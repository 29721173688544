import React, { useEffect } from "react";
import {
  CssBaseline,
  Grid,
  Card,
  Container,
  CardContent,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ActionCreators } from "../redux/actions";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Alerts from "../components/Alert/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  clstitle: {
    padding: "3px",
    fontSize: "15px !important",
    opacity: 0.8,
  },
  clsHeader: {
    fontSize: "22px",
    opacity: 0.8,
    padding: "10px 0px 10px 0px",
  },
}));

const Registration = (props) => {
  const classes = useStyles();
  const { slug } = useParams();
  useEffect(() => {
    props.actions.getReatilerDetail(slug);
  }, [props.actions]);

  const onHandleMove = () => {
    window.location.href = "/consumer";
  };

  const retailer_detail = useSelector((state) => state.RetailerReducers.data);

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <CssBaseline />
      <Card>
        <CardContent>
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
            >
              <Box className={classes.clsHeader}>
                <Typography
                  variant="h5"
                  component="div"
                  justifyContent="center"
                >
                  <b> Retailer Info </b>
                  <br />
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography className={classes.clstitle}>Company Name</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.clstitle}>
                {retailer_detail?.data?.name}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography className={classes.clstitle}>Email</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.clstitle}>
                {" "}
                {retailer_detail?.data?.email}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography className={classes.clstitle}>Address</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.clstitle}>
                {retailer_detail?.data?.address[0].line1},{" "}
                {retailer_detail?.data?.address[0].line2},{" "}
                {retailer_detail?.data?.address[0].city}
                <br />
                {retailer_detail?.data?.address[0].country}{" "}
                {retailer_detail?.data?.address[0].zipcode}
              </Typography>
            </Grid>

            {retailer_detail && retailer_detail.error !== null && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box sx={{ marginTop: "10px" }}>
                    <Alerts type="error" message={retailer_detail.error} />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  marginRight: "4px",
                  justifyContent: "center",
                  float: "right",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onHandleMove()}
                >
                  Apply Now
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
});

export default connect(null, mapDispatchToProps)(Registration);
