import * as contractRequest from '../../services/contractRequest';
import * as actionTypes from './actionTypes';


export const contractRequestsSave = (data) =>
	async dispatch => {
		dispatch(conttractRequestRequest());
		try {
			const user = await contractRequest.contractRequestsSave(data);
			
			if (user && user.data && user.data.status === 1) {
				dispatch(conttractRequestLoaded(user.data));
			} else {
				dispatch(conttractRequestFailure(user.data.message));
			}
		} catch (error) {
			dispatch(conttractRequestFailure(error.message));
		}
	}
  

export const stateInit=()=>
	async dispatch=>{
		dispatch(conttractRequestStateinit());
	}



const conttractRequestStateinit = () => {
	return {
		type: actionTypes.CONTRACTREQUEST_INIT
	}
}

const conttractRequestRequest = () => {
	return {
		type: actionTypes.CONTRACTREQUEST_REQUEST
	}
}

const conttractRequestFailure = (error) => {
	return {
		type: actionTypes.CONTRACTREQUEST_FAILURE,
		payload: error
	}
}

const conttractRequestLoaded = (data) => {
	return {
		type: actionTypes.CONTRACTREQUEST_LOADED,
		payload: data
	}
}


export const getContractRequestsList = (data) =>
	async dispatch => {
		dispatch(conttractRequestListRequest());
		try {
			const user = await contractRequest.getContractRequestsList();
			
			if (user && user.data && user.data.status === 1) {
				dispatch(conttractRequestListLoaded(user.data));
			} else {
				dispatch(conttractRequestListFailure(user.data.message));
			}
		} catch (error) {
			dispatch(conttractRequestListFailure(error.message));
		}
	}
  

	const conttractRequestListRequest = () => {
		return {
			type: actionTypes.CONTRACTREQUESTLIST_REQUEST
		}
	}
	
	const conttractRequestListFailure = (error) => {
		return {
			type: actionTypes.CONTRACTREQUESTLIST_FAILURE,
			payload: error
		}
	}
	
	const conttractRequestListLoaded = (data) => {
		return {
			type: actionTypes.CONTRACTREQUESTLIST_LOADED,
			payload: data
		}
	}
	