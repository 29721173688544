import * as React from 'react';
import {
  TextField,
  Link,
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from '@mui/material';
import {Facebook,Google,LockOutlined} from '@mui/icons-material';
import Alerts from '../../components/Alert/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

export default function SignIn(props){
  const {user_detail}=props
const [values, setValues] = React.useState({
  data: {
      email: '',
      password: '',
  },
  required: {
      email: '',
      password: '',
  }
})

const handleChange = (prop) => (event) => {

  var validData = "success";
  let required = values.required;
  let data = values.data
  required[prop] = validData
  data[prop] = event.target.value

      setValues({
          ...values,
          ['data']: data,
          ['required']: required
      });
};

const onLoginClick = () => {
  if(validation()){
  props.signInUser(values.data)
  }
}

const validation = () => {
  var reqData = values['required'];
  var isValid = true;

  if (!values.data.hasOwnProperty("email") || (values.data.email.trim() === "" || reqData["email"] === "error")) {
      reqData["email"] = "error";
      isValid = false;
  }

  if (!values.data.hasOwnProperty("password") || (values.data.password.trim() === "" || reqData["password"] === "error")) {
      reqData["password"] = "error";
      isValid = false;
  }

  setValues({
      ...values,
      'required': reqData
  });
  return isValid;
}

  return (
        <Grid container direction='row'>
              <Grid item xs={12}>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs style={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}>
                         <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
       
          <Box component="form"  noValidate sx={{ mt: 1 }}>
            
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email "
                              name="email"
                              value={values.data.email}
                              error={values.required.email === "error"}
                              onChange={handleChange('email')}
                            />
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                              value={values.data.password}
                              error={values.required.password === "error"}
                              onChange={handleChange('password')}
                            />
                           { user_detail.error!==null&& <Alerts type="error" message={user_detail.error}/>}
                            <LoadingButton
                              color="primary"
                              onClick={()=>onLoginClick()}
                              loading={user_detail.loading}
                              loadingPosition="start"
                              sx={{ mt: 3, mb: 2 }}
                              fullWidth
                              variant="contained"
                            > 
                              Sign In  
                            </LoadingButton>
                            <Grid container>
                            <Grid  xs={6} lg={6}>
                              <Link href="/registration" variant="body2">
                                {"Sign Up as Lender "}
                              </Link>
                            </Grid>
                          <Grid  xs={6} lg={6}>
                              <Link href="/registration/retailer" variant="body2"  sx={{float:'right'}}>
                                {"Sign Up as Retailer"}
                              </Link>
                            </Grid> 
                            </Grid>                       
                            <br/>
                            <Grid container style={{textAlign:'center',}}>
                              <Grid item xs>
                                <Facebook
                                color="primary"  
                                style={{margin: '5px',
                                fontSize: '25px'}}
                                />
                                <Google
                                color="primary" 
                                style={{margin: '5px',
                                fontSize: '25px'}}
                                />
                              </Grid>
                            </Grid>
          </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  );
}