import  React,{useState,useEffect} from 'react';
import {
  Container,
  Typography
} from '@mui/material';
import RetailerInfo from '../components/Card/RetailerInfo'
import ContractRequests from '../components/Card/ContractRequests';
import { ActionCreators } from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

  const Homepage = (props) => {

  useEffect(()=>{
    props.actions.getContractRequestsList()
},[props.actions])

  return (
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
        Home
        </Typography> 
      </Container>
  )
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

export default connect(null, mapDispatchToProps)(Homepage);