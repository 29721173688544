import React from 'react'
import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';
import { ActionCreators } from '../../../redux/actions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { localStorageUtils } from '../../../utils'
const useStyles = makeStyles((theme) => ({
 clsAppBar:{
  lineHeight: '0.6px !important',
 },
 clsImg:{
   height:'54px',
   width:'200px'
 },
 clsIcon:{
   float:'right'
 }
}));

const Header = (props) =>  {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout=()=>{
    localStorageUtils.removeToken("token");
    props.actions.signOutUser()
  }

  return (
    <Box sx={{ flexGrow: .1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className={classes.clsAppBar}>
          <Link href={"/dashboard"} underline="none" sx={{color:"#fff"}}>
            <b> {"Lender Portal"}</b>
          </Link>  
          </Typography>
            <div className={classes.clsImg}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className={classes.clsIcon}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
               
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

export default connect(null, mapDispatchToProps)(Header);