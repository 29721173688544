export const CONTRACTREQUEST_REQUEST = 'contractrequests_request';
export const CONTRACTREQUEST_FAILURE = 'contractRequests_failure';
export const CONTRACTREQUEST_LOADED = 'contractrequests_loaded'
export const CONTRACTREQUEST_INIT = 'contractrequests_init'


export const CONTRACTREQUESTLIST_REQUEST = 'contractrequestslist_request';
export const CONTRACTREQUESTLIST_FAILURE = 'ccontractrequestslist_failure';
export const CONTRACTREQUESTLIST_LOADED = 'contractrequestslist_loaded'



